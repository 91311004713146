import React from "react"
import { v4 as uuidv4 } from "uuid"
import armin from "../../images/armin_1.jpg"
import armin2 from "../../images/armin_2.jpg"
import employee1_1 from "../../images/employee1_1.jpg"
import employee1_2 from "../../images/employee1_2.jpg"
import employee2_1 from "../../images/employee2_1.jpg"
import employee2_2 from "../../images/employee2_2.jpg"
import employee3_1 from "../../images/employee3_1.jpg"
import employee3_2 from "../../images/employee3_2.jpg"
import employee4_1 from "../../images/employee4_1.jpg"
import employee4_2 from "../../images/employee4_2.jpg"
import employee5_1 from "../../images/employee5_1.jpg"
import employee5_2 from "../../images/employee5_2.jpg"
import employee6_1 from "../../images/employee6_1.jpg"
import employee6_2 from "../../images/employee6_2.jpg"
import employee7_1 from "../../images/employee7_1.jpg"
import employee7_2 from "../../images/employee7_2.jpg"
import { StaticImage } from "gatsby-plugin-image"

const people = [
  {
    name: "Armin Voser",
    role: "Geschäftsführer",
    imageUrl: armin,
    imageHoverUrl: armin2,
  },
  {
    name: "Federica Voser",
    role: "Finanzen & Personal",
    imageUrl: employee4_1,
    imageHoverUrl: employee4_2,
  },
  {
    name: "Roman Treuli",
    role: "Kalkulator",
    imageUrl: employee6_1,
    imageHoverUrl: employee6_2,
  },
  {
    name: "Haris Hidic",
    role: "Projektleiter",
    imageUrl: employee7_1,
    imageHoverUrl: employee7_2,
  },
  {
    name: "Carolina Di Santo",
    role: "Projektleiterin",
    imageUrl: employee3_1,
    imageHoverUrl: employee3_2,
  },
  {
    name: "Ricardo Sanchez",
    role: "Montageleiter",
    imageUrl: employee2_1,
    imageHoverUrl: employee2_2,
  },
  {
    name: "Danijel Todorovic",
    role: "Montageleiter",
    imageUrl: employee1_1,
    imageHoverUrl: employee1_2,
  },
  {
    name: "Krist Gjetaj",
    role: "Montageleiter",
    imageUrl: employee5_1,
    imageHoverUrl: employee5_2,
  },
]

export default function Team() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 py-4 mb-12 md:px-0 md:py-0 ">
        <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
          <h2 className="text-3xl font-extrabold tracking-tight mb-4 sm:text-4xl">
            Unser Team
          </h2>
        </div>

        <ul
          role="list"
          className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8"
        >
          {people.map(person => (
            <li key={uuidv4()}>
              <div className="space-y-4">
                <div className="aspect-w-3 aspect-h-2 cursor-pointer">
                  <img
                    className="object-cover shadow-lg rounded-lg"
                    src={person.imageUrl}
                    alt={uuidv4()}
                    onMouseOver={e => {
                      e.currentTarget.src = person.imageHoverUrl
                      e.currentTarget.classList.add(
                        "transition",
                        "duration-500",
                        "ease-in-out",
                        "transform",
                        "hover:-translate-y-4",
                        "hover:scale-100"
                      )
                    }}
                    onMouseOut={e => (e.currentTarget.src = person.imageUrl)}
                  />
                </div>

                <div className="space-y-2">
                  <div className="text-lg leading-6 font-medium space-y-1">
                    <h3>{person.name}</h3>
                    <p className="text-blue-600">{person.role}</p>
                  </div>
                  <ul role="list" className="flex space-x-5">
                    <li>
                      <a
                        href={`https://www.linkedin.com/in/${person.name.replace(
                          " ",
                          "-"
                        )}`}
                        target="_blank"
                        className="text-gray-400 hover:text-gray-500"
                      >
                        <span className="sr-only">LinkedIn</span>
                        <svg
                          className="w-5 h-5"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
