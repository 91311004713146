import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Team from "../components/team"

const TeamPage = () => (
  <Layout>
    <Seo title="Team" />
    <Team />
  </Layout>
)

export default TeamPage
